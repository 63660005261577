import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import { FaVolumeHigh } from "react-icons/fa6";
import { GiProgression } from "react-icons/gi";
import "./ViewVideo.css";
import API_BASE_URL from './config';

const ViewVideo = (props) => {
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [played, setPlayed] = useState(0);
  const [duration, setDuration] = useState(0);
  const [seeking, setSeeking] = useState(false);

  const [doubt, setDoubt] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  

  const playerRef = useRef(null);
  const filename = props.props;
  // const [video_id, setvideo_id] = useState(filename);
  // const url = `${API_BASE_URL}/sftp_video/${filename}`;
  const url = `${API_BASE_URL}/video/${filename}`;
  console.log("video url is:", url)

  useEffect(() => {
    setPlaying(true); // Auto play the video when component mounts
  }, []);

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handleVolumeChange = (e) => {
    setVolume(parseFloat(e.target.value));
  };

  const handleProgress = (state) => {
    if (!seeking) {
      setPlayed(state.played);
    }
  };

  const handleDuration = (d) => {
    setDuration(d);
  };

  const handleSeekChange = (e) => {
    const fraction = parseFloat(e.target.value);
    setPlayed(fraction);
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const handleSeekMouseUp = (e) => {
    setSeeking(false);
    const fraction = parseFloat(e.target.value);
    const newPositionInSeconds = fraction * duration;
    playerRef.current.seekTo(newPositionInSeconds);
  };

  const handleFullscreen = () => {
    playerRef.current?.getInternalPlayer()?.requestFullscreen();
  };

  const handleDoubtSubmit = async (event) => {
    event.preventDefault();


    if (doubt.length > 5) {
      setLoading(true);

      try {

        const studentUniqueId = localStorage.getItem("student_unique_id");
        const token = localStorage.getItem("token");
        if (studentUniqueId && token) {

          const formData = new FormData();
          formData.append('student_id', studentUniqueId);
          formData.append('token', token);
          formData.append('doubt_id', studentUniqueId+"_"+filename);
          formData.append('doubt', doubt);

          const response = await fetch(`${API_BASE_URL}/save_doubt/`, {
            method: "POST",
            body: formData,
          });

          const data = await response.json();

          if (data.status === 'success') {
            setMessage('Doubt submitted successfully!');
            setDoubt('');
          } else {
            setMessage('Failed to submit doubt: ' + data.message);
          }
        }

        else {
          setMessage('Error: Auth');

        }
      } catch (error) {
        setMessage('Error: ' + error.message);
      } finally {
        setLoading(false);
      }


      setTimeout(() => {
        setMessage('');
      }, 5000);
    } else {
      setMessage('Doubt must be more than 5 characters');
    }
  };


  return (
    <>
      <div className="player-container">
        <ReactPlayer
          ref={playerRef}
          url={url}
          playing={playing}
          volume={volume}
          onPlay={() => setPlaying(true)}
          onPause={() => setPlaying(false)}
          onProgress={handleProgress}
          onDuration={handleDuration}
          width="100%"
          height="60vh"
          controls={true}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload",
              },
            },
          }}
          className="react-player"
        />
      </div>

      <div>
        <div className="fun-btn">
          <button className="controlButton" onClick={handlePlayPause}>
            {playing ? "Pause" : "Play"}
          </button>
          <button className="controlButton" onClick={handleFullscreen}>
            Fullscreen
          </button>
          <div className="volume-fun">
            <div>
              <FaVolumeHigh />
            </div>
            <div>
              <input
                type="range"
                className="volumeSlider"
                min={0}
                max={1}
                step="any"
                value={volume}
                onChange={handleVolumeChange}
              />
            </div>
          </div>

          <div className="progress-fun">
            <div>
              <GiProgression />
            </div>
            <div>
              <input
                type="range"
                className="progressSlider"
                min={0}
                max={1}
                step="any"
                value={played}
                onChange={handleSeekChange}
                onMouseDown={handleSeekMouseDown}
                onMouseUp={handleSeekMouseUp}
              />
            </div>
          </div>
        </div>
      </div>


      <div className="doubt-section">
        <h2>Ask a Doubt</h2>
        <form id="doubtForm" onSubmit={handleDoubtSubmit}>
          <textarea
            id="doubtInput"
            placeholder="Enter your doubt here..."
            value={doubt}
            onChange={(e) => setDoubt(e.target.value)}
            required
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Submitting...' : 'Submit'}
          </button>
        </form>


        {loading && <div className="loading-icon">Loading...</div>}


        {message && <div className="message">{message}</div>}
      </div>

    </>
  );
};

export default ViewVideo;
