import React, { useState } from "react";
import "./TakeAdmission.css"; // Import CSS file
import {API_BASE_URL} from './config'; 

const TakeAdmission = ({ onClose }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    dob: "",
    education: "",
    password: "",
    confirmPassword: "",
  });

  const [submitted, setSubmitted] = useState(false); // State to track submission
  const [errorMessage, setErrorMessage] = useState(""); // State to handle validation errors

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const { firstName, lastName, mobileNumber, email, dob, education, password } = formData;

    if (!firstName || !lastName || !mobileNumber || !email || !dob || !education || !password) {
      setErrorMessage("Please fill all required fields.");
      return false;
    }
    setErrorMessage(""); // Clear error if form is valid
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form before making the request
    if (!validateForm()) {
      return;
    }

    // Assuming the form is valid, proceed with submission and show the success message
    setSubmitted(true); // Mark as submitted to hide form and show message

    setTimeout(() => {
      onClose(); // Call the onClose prop to close the box
    }, 2000);

    try {
      const url = `${API_BASE_URL}/signin?first_name=${encodeURIComponent(
        formData.firstName
      )}&last_name=${encodeURIComponent(
        formData.lastName
      )}&mobile_number=${encodeURIComponent(
        formData.mobileNumber
      )}&email=${encodeURIComponent(formData.email)}&dob=${encodeURIComponent(
        formData.dob
      )}&education=${encodeURIComponent(
        formData.education
      )}&pay_status=${encodeURIComponent(
        "paid"
      )}&password=${encodeURIComponent(
        formData.password
      )}&confirm_password=${encodeURIComponent(formData.confirmPassword)}`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      // eslint-disable-next-line
      const data = await response.json();
      

      if (response.ok) {
        console.log("Sign up successful!");

        // Close the box after 2 seconds
        setTimeout(() => {
          onClose(); // Call the onClose prop to close the box
        }, 2000);
      } else {
        console.error("Sign up failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  return (
    <div className="take-admission-container">
      <h2>Admission Form</h2>
      <div>
        <button className="close-btn" onClick={onClose}>
          Close
        </button>
      </div>

      {/* Conditionally render either the form or the success message */}
      {!submitted ? (
        <div className="take-admission-form">
          <h2>Fill Information</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="labeldiv">
                <label>First Name:</label>
              </div>
              <div className="inputdiv">
                <input
                  type="text"
                  name="firstName"
                  placeholder="Enter first name"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <div className="labeldiv">
                <label>Last Name:</label>
              </div>
              <div className="inputdiv">
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  placeholder="Enter last name"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="labeldiv">
                <label>Mobile Number:</label>
              </div>
              <div className="inputdiv">
                <input
                  type="text"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                  required
                  placeholder="Enter mobile number"
                  maxLength={10}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="labeldiv">
                <label>Email:</label>
              </div>
              <div className="inputdiv">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  placeholder="Enter email address"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="labeldiv">
                <label>Date of Birth:</label>
              </div>
              <div className="inputdiv">
                <input
                  type="date"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <div className="labeldiv">
                <label>Education:</label>
              </div>
              <div className="inputdiv">
                <input
                  type="text"
                  name="education"
                  value={formData.education}
                  onChange={handleChange}
                  required
                  placeholder="Enter higher education"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="labeldiv">
                <label>Password:</label>
              </div>
              <div className="inputdiv">
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  placeholder="Enter password"
                />
              </div>
            </div>
            {errorMessage && (
              <div className="error-message">
                <p>{errorMessage}</p>
              </div>
            )}
            <div className="submit-btn">
              <button className="submit-btn" type="submit">
                Sign Up
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="success-message">
          <h3>Our team will contact you soon!</h3>
        </div>
      )}
    </div>
  );
};

export default TakeAdmission;
