import React, { useState, useEffect, useContext } from "react";
import topic from "./syllabus.json";
import "./CompleteSyllabus.css";
import ViewVideo from "./ViewVideo";
import Papa from "papaparse";
import csvFile from './updated_subtopic_percentage_masters.csv';
import csvFile2 from './main_topic_percentage_masters.csv';
import ProgressContext from './ProgressContext';
import { API_BASE_URL } from './config';
import { RotatingLines } from "react-loader-spinner";




const CompleteSyllabus = () => {
  const { setProgress } = useContext(ProgressContext);
  const [openGroup, setOpenGroup] = useState(null);
  const [openSubTopic, setOpenSubTopic] = useState(null);
  const [SubTopicKey, setSubTopicKey] = useState(null);
  const [percentages, setPercentages] = useState({});
  const [percentagesSub, setPercentagesSub] = useState({});
  const [DayDuration, setDayDuration] = useState({});
  const [CurrentDay, setCurrentDay] = useState(0);
  const [showModal, setShowModal] = useState(false);
  // eslint-disable-next-line
  const [ProgressResponse, setProgressResponse] = useState(null);
  const [topicTotalData, setTopicTotalData] = useState([]);
  const [completedTopics, setCompletedTopics] = useState([]);

  const [loading, setLoading] = useState({
    endpoint1: true,
    endpoint2: true,
   
    
  });

  useEffect(() => {
    // Fetch and parse the CSV files
    fetch(csvFile)
      .then((response) => response.text())
      .then((data) => {
        Papa.parse(data, {
          header: true,
          complete: (results) => {
            const percentageMapping = {};
            const percentageSubMapping = {};
            results.data.forEach((row) => {
              percentageMapping[row.main_topic] = row.Percentage;
              percentageSubMapping[row.video_title] = row.subtopic_percentage;
            });
            setPercentages(percentageMapping);
            setPercentagesSub(percentageSubMapping);
          },
        });
      });

    fetch(csvFile2)
      .then((response) => response.text())
      .then((data) => {
        Papa.parse(data, {
          header: true,
          complete: (results) => {
            const DayMapping = {};
            results.data.forEach((row) => {
              DayMapping[row.Topic] = [row.Start_Day, row.End_Day];
            });
            setDayDuration(DayMapping);
          },
        });
      });

    async function fetchUserDetails() {
      try {
        const studentUniqueId = localStorage.getItem("student_unique_id");
        const token = localStorage.getItem("token");
        if (studentUniqueId && token) {
          const response = await fetch(
            `${API_BASE_URL}/student_JoinDate/${studentUniqueId}?token=${token}`,
            {
              method: "GET",
              headers: {
                Accept: "application/json",
              }

            }
          );
          const data = await response.json();
          // console.log(data)
          if (response.ok) {

            setCurrentDay(data["days_difference"]);
            setLoading((prevLoading) => ({ ...prevLoading, endpoint1: false }))
          } else {
            console.error("Failed to fetch details:", response.statusText);
          }
        } else {
          console.error("Student Unique ID not found in local storage");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    }

    async function fetchSyllabusCompletedStatus() {
      try {
        const studentUniqueId = localStorage.getItem("student_unique_id");
        const token = localStorage.getItem("token");
        if (studentUniqueId && token) {
          const data = {
            student_id: studentUniqueId,
            token: token
          };

          const response = await fetch(`${API_BASE_URL}/init_syllabus_progress_info/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
          });

          if (response.ok) {
            const responseData = await response.json();
            setProgress(responseData.total_addition); // Update progress in context
            setProgressResponse(responseData.total_addition);
            setTopicTotalData(responseData.topic_total_df);
            setCompletedTopics(responseData.topic_total_video); // Set completed topics from the response
            setLoading((prevLoading) => ({ ...prevLoading, endpoint2: false }))
          } else {
            console.error("Error fetching progress data:", response.statusText);
          }
        } else {
          console.error("Student Unique ID not found in local storage");
        }
      } catch (error) {
        console.error("Error fetching progress data:", error);
      }
    }




    fetchUserDetails();
    fetchSyllabusCompletedStatus();
    // eslint-disable-next-line
  }, []);

  const toggleSubTopic = (subTopicKeyInner) => {
    setOpenSubTopic((prevOpenGroup) =>
      prevOpenGroup === subTopicKeyInner ? null : subTopicKeyInner
    );
  };

  const toggleGroup = (groupName, StartDuration, EndDuration) => {
    if (CurrentDay >= StartDuration) {
      setOpenGroup((prevOpenSubTopic) =>
        prevOpenSubTopic === groupName ? null : groupName
      );
    } else {
      setShowModal(true);
    }
  };

  const getSubtopicPercentage = (topic) => {
    const topicObject = topicTotalData.find(item => item.main_topic === topic);
    return topicObject ? topicObject.subtopic_percentage : null;
  };

  const update_progress = async (title) => {
    const studentUniqueId = localStorage.getItem("student_unique_id");
    const token = localStorage.getItem("token");
    if (studentUniqueId && token) {
      const data = {
        student_id: studentUniqueId,
        video_title: title,
        token: token
      };

      try {
        const response = await fetch(`${API_BASE_URL}/add_syllabus_progress_info`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
        });

        if (response.ok) {
          const responseData = await response.json();
          console.log("Response Dasta:", responseData);
          // eslint-disable-next-line
          const totalAddition = responseData.total_addition;
          // eslint-disable-next-line
          const topicTotalData = responseData.topic_total_df;

          setProgress(responseData.total_addition); // Update progress in context
          setProgressResponse(responseData.total_addition);
          setTopicTotalData(responseData.topic_total_df);

          // Add the completed topic to the state
          setCompletedTopics((prevCompletedTopics) => [...prevCompletedTopics, title]);
          // setLoading((prevLoading) => ({ ...prevLoading, endpoint3: false }))
          // console.log(completedTopics)

        } else {
          console.error("Error:");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const UpDateSubKey = (groupName) => {
    const fileName = `${groupName}.mp4`;
    setSubTopicKey(fileName);
  };

  const isLoading = Object.values(loading).some((status) => status);
  console.log("###",isLoading)
  console.log(loading)

  if (isLoading) {
    return (<div className="load-test"><RotatingLines
      strokeColor="grey"
      strokeWidth="5"
      animationDuration="0.75"
      width="96"
      visible={true}
    /></div>
    );
  }

  return (
    <div className="topic-div">
      {!showModal && topic.Topic_Menu.map((topicGroup) => {
        return Object.entries(topicGroup).map(([groupNumber, groupData]) => {
          const [groupName, groupContent] = Object.entries(groupData)[0];
          const percentage = percentages[groupName] || "N/A";
          const StartDuration = DayDuration[groupName] ? DayDuration[groupName][0] || "N/A" : "N/A";
          const EndDuration = DayDuration[groupName] ? DayDuration[groupName][1] || "N/A" : "N/A";
          const subtopicPercentage = getSubtopicPercentage(groupName);
          // console.log(subtopicPercentage)
          const pend_comp_status = subtopicPercentage === percentage ? "completed" : "Pending"

          return (
            <div key={groupNumber} className="topic-group2">
              <div className="main-topic2">
                <h2>
                  <button className="main-topic3" onClick={() => toggleGroup(groupName, StartDuration, EndDuration)}>
                    <span className="main-topic-name">{`${groupNumber}.${groupName}`} </span>
                    <span className="ce-text">
                      {subtopicPercentage !== null ? (
                        <div className="complete-text">
                          {`${Number(subtopicPercentage).toFixed(2)} % completed`}
                        </div>
                      ) : (
                        <div className="empty-text">{`${Number(subtopicPercentage).toFixed(2)} % completed`}</div>
                      )}
                    </span>

                    <span className="second-text">{percentage}%</span>
                    <span className="StartDuration-text">Day-{StartDuration} to Day-{EndDuration}</span>
                    <div className={`complete-status ${pend_comp_status === "completed" ? 'completed' : 'pending'}`}><span className="complete-status-span">{pend_comp_status}</span></div>
                  </button>
                </h2>
              </div>

              {openGroup === groupName && (
                <ul className="ul-out">
                  {groupContent.map((subTopic, i) => {
                    const subTopicVal = Object.values(subTopic)[0];
                    const subTopicKeyInner = Object.keys(subTopicVal)[0];
                    const subTopicvalInner = Object.values(subTopicVal)[0];

                    return (
                      <React.Fragment key={i}>
                        <button
                          className="sub-css"
                          onClick={() => toggleSubTopic(subTopicKeyInner)}
                        >
                          {`${i + 1} - ${subTopicKeyInner}`}
                        </button>

                        {openSubTopic === subTopicKeyInner && (
                          <ul>
                            {Array.isArray(subTopicvalInner) ? (
                              <ul>
                                {subTopicvalInner.map((item, index) => (
                                  <li className="li-dot-remove" key={index}>
                                    <div className="sub-topic-list">
                                      <div className="in-topic-name">{`${index + 1} - ${item}`}</div>
                                      <div className="in-btn-name">
                                        <button
                                          className="btn-upload"
                                          onClick={() =>
                                            UpDateSubKey(
                                              `${groupName}-${subTopicKeyInner}-${item}`
                                            )
                                          }
                                        >
                                          View Video
                                        </button>
                                      </div>
                                      <div className="in-per-name">
                                        {percentagesSub[item] !== undefined
                                          ? (
                                            <>
                                              <div className="second-text2">
                                                {`${Number(percentagesSub[item]).toFixed(2)} %`}
                                              </div>
                                            </>
                                          )
                                          : 'N/A'}
                                      </div>
                                      <div className="Mark-Complete">
                                        <button
                                          onClick={() => update_progress(item)}
                                          className={`button-common ${completedTopics.includes(item) ? 'complete-btn' : 'mark-btn'}`}
                                          disabled={completedTopics.includes(item)}
                                        >
                                          {completedTopics.includes(item) ? "Completed" : "Mark Complete"}
                                        </button>
                                      </div>

                                    </div>
                                    {`${groupName}-${subTopicKeyInner}-${item}.mp4` ===
                                      SubTopicKey && (
                                        <ViewVideo
                                          props={`${groupName}-${subTopicKeyInner}-${item}.mp4`}
                                        />
                                      )}
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              subTopicvalInner
                            )}
                          </ul>
                        )}
                      </React.Fragment>
                    );
                  })}
                </ul>
              )}
            </div>
          );
        });
      })}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <p className="model-text">
              <strong>Hey buddy,</strong> <br /><br />
              <div className="modal-font">It's not the right time to start new material. Focus on finishing the current syllabus first. If that's done, concentrate on revising, practicing, and doing more exercises to master the content. Only then should you move on, and make sure it's within a reasonable timeframe.</div>
            </p>
            <button className="modal-btn" onClick={() => setShowModal(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompleteSyllabus;
