// For Local
// const API_BASE_URL = "http://127.0.0.1:8000";

// For remote access
const API_BASE_URL = "https://snehachievers.in/api";
const LOGOUT_URL = "https://snehachievers.in";

// For Kubernetes
// const API_BASE_URL = "http://192.168.49.2:31699";

export { API_BASE_URL, LOGOUT_URL };
