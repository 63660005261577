import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import "./StudentLogin.css";
import StudentDashBoad from "./StudentDashBoad";
import CryptoJS from "crypto-js";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import API_BASE_URL from "./config";
import { isMobile, isTablet, isDesktop } from "react-device-detect";

const StudentLogin = ({ setCurrentPage }) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [OpenStudentDashboard, setOpenStudentDashboard] = useState(false);
  const [ExistingUser, setExistingUser] = useState(false);
  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);
  const [NewUserMail, setNewUserMail] = useState("");

  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");

  const [NewUserPanelmsg1, setNewUserPanelmsg1] = useState(true);
  const [NewUserPanelmsg2, setNewUserPanelmsg2] = useState(false);

  const [Submit1, setSubmit1] = useState(true);
  const [Submit2, setSubmit2] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);

  const [incorrect_password, setincorrect_password] = useState("");

  const [isFPModalOpen, setisFPModalOpen] = useState(false);

  const [FPemail, setFPemail] = useState("");

  // eslint-disable-next-line
  const [EmailFPSubmit, setEmailFPSubmit] = useState(false);

  const [error, setError] = useState("");
  const [wrongmail, setwrongmail] = useState("");

  const [EmailVerified, setEmailVerified] = useState(false);

  const [OTPsendSuccess, setOTPsendSuccess] = useState(false);

  const [EnteredOTP, setEnteredOTP] = useState("");

  const [verifiedOTP, setverifiedOTP] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [OTPCheck, setOTPCheck] = useState(true);

  const [modal1open, setmodal1open] = useState(true);
  const [modal2open, setmodal2open] = useState(false);
  const [modal3open, setmodal3open] = useState(false);
  const [DesktopDevice, IsDesktopDevice] = useState(false);

  const [extraletters, setextraletters] = useState("");

  useEffect(() => {
    const authenticateUser = async () => {
      const studentUniqueId = localStorage.getItem("student_unique_id");
      const token = localStorage.getItem("token");

      if (studentUniqueId && token) {
        try {
          const response = await fetch(`${API_BASE_URL}/verify_token`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              token: token, // Changed from 'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ student_id: studentUniqueId }),
          });

          const data = await response.json();
          if (response.ok && data === "success") {
            setIsModalOpen(false);
            setOpenStudentDashboard(true);
            const upparNav = document.querySelector(".uppar-nav");
            if (upparNav) {
              upparNav.style.display = "none";
            }
          } else {
            setCurrentPage("StudentLogin");
            localStorage.removeItem("student_unique_id");
            localStorage.removeItem("token");
          }
        } catch (error) {
          console.error("An error occurred during token verification:", error);
          setCurrentPage("StudentLogin");
          localStorage.removeItem("student_unique_id");
          localStorage.removeItem("token");
        }
      } else {
        setCurrentPage("StudentLogin");
        localStorage.removeItem("student_unique_id");
        localStorage.removeItem("token");
      }
    };

    authenticateUser();
    // eslint-disable-next-line
  }, []);

  // Function to open modal
  const openModal = () => {
    setIsModalOpen(false);
    setisFPModalOpen(true);
    setmodal1open(false);
    setmodal2open(true);
  };

  // Function to close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentPage("StudentLogin"); // Set the current page to StudentLogin
  };

  // Function to handle mobile number input change
  const handleMobileChange = (e) => {
    const value = e.target.value;
    // Accept only 10 digits
    if (/^\d{0,10}$/.test(value)) {
      setMobileNumber(value);
    }
  };

  // Function to handle password input change
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    // Check if password length is greater than 5
    setIsPasswordValid(value.length > 5);
  };

  const handleEmailChange = (e) => {
    setNewUserMail(e.target.value);
  };

  const handleFirstNameChange = (e) => {
    setfirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setlastName(e.target.value);
  };
  // eslint-disable-next-line
  const encryptDataWithSalt = (data, salt) => {
    // Encrypt data using AES encryption with salt
    const encrypted = CryptoJS.AES.encrypt(data, salt).toString();
    return encrypted;
  };

  // Function to save encrypted data to local storage
  const saveToLocalStorage = (key, value) => {
    // Generate a random salt
    // const salt = CryptoJS.lib.WordArray.random(128 / 8).toString(
    //   CryptoJS.enc.Base64
    // );
    // Save salt to local storage
    localStorage.setItem(`${key}`, value);
    // Encrypt data with salt
    // const encryptedValue = encryptDataWithSalt(value, salt);
    // localStorage.setItem(key, encryptedValue);
  };

  async function saveMobileNumber(mobileNumber) {
    try {
      const response = await fetch(
        `${API_BASE_URL}/probable_student_mobile_save?mobile_number=${mobileNumber}&status=new_prob`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        console.log("Mobile number saved successfully.");
        closeModal();
        setIsNewUserModalOpen(true);
        setmodal3open(true);
      } else {
        console.error("Failed to save mobile number:", data.detail);
      }
    } catch (error) {
      console.error("An error occurred while saving mobile number:", error);
    }
  }

  async function saveProbableStudentDetails(
    firstName,
    lastName,
    email,
    mobileNumber
  ) {
    try {
      const response = await fetch(
        `${API_BASE_URL}/probable_student_all_info_save?first_name=${firstName}&last_name=${lastName}&email=${encodeURIComponent(
          email
        )}&mobile_number=${mobileNumber}&status=new_prob`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        console.log("Student details saved successfully.");

        setNewUserPanelmsg2(true);

        // Redirect to the specified URL after a delay of 2 seconds
        setTimeout(() => {
          setIsNewUserModalOpen(false);
          window.location.href = "https://snehachievers.in/";
        }, 2000); // Delay in milliseconds (2 seconds = 2000 milliseconds)

        ///

        ///
      } else {
        console.error("Failed to save student details:", data.detail);
      }
    } catch (error) {
      console.error("An error occurred while saving student details:", error);
    }
  }

  async function LogIn(mobileNumber, password) {
    try {
      const response = await fetch(
        `${API_BASE_URL}/login?mobile_number=${mobileNumber}&password=${password}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
        }
      );
      // const data = await response.json();
      if (response.ok) {
        // Login successful, handle your logic here

        console.log("Studentdashboard should open");
        closeModal();
        setOpenStudentDashboard(true);
        const upparNav = document.querySelector(".uppar-nav");
        if (upparNav) {
          upparNav.style.display = "none";
        }

        const responseData = await response.json();
        console.log("Login successful:", responseData);
        saveToLocalStorage("student_unique_id", responseData.student_unique_id);
        saveToLocalStorage("token", responseData.token);
        setincorrect_password("");
      } else {
        console.error("Login failed:");
        setincorrect_password("incorrect password");
      }
    } catch (error) {
      console.error("An error occurred during login:", error);
    }
  }

  // eslint-disable-next-line
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  // Function to handle form submission
  const handleSubmit2 = async (e) => {
    e.preventDefault();
    if (isPasswordValid) {
      await LogIn(mobileNumber, password);
      // Find the div with name 'uppar-nav' and hide it
    } else {
      alert("Password must be at least 6 characters long.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Make a request to your API
      console.log(
        `Called API is : ${API_BASE_URL}/check_mobile_number/${mobileNumber}`
      );
      console.log(isDesktop, isTablet, isMobile);
      const response = await fetch(
        `${API_BASE_URL}/check_mobile_number/${mobileNumber}`
      );
      const data = await response.json();
      // console.log("%%%%", data)

      // Check the response and handle accordingly
      if (data.exists) {
        // Handle case when mobile number exists in the database
        console.log("Mobile number exists in the database.");
        if (isDesktop) {
          setIsNewUserModalOpen(false);
          setExistingUser(true);
          setSubmit1(false);
          setSubmit2(true);
          localStorage.setItem("student_unique_id", data.student_id);
        } else {
          setIsNewUserModalOpen(false);
          setExistingUser(false);
          setSubmit1(false);
          setSubmit2(false);
          IsDesktopDevice(true);
          setmodal1open(false);
        }
      } else {
        // Handle case when mobile number does not exist in the database
        console.log("Mobile number does not exist in the database.");
        await saveMobileNumber(mobileNumber);
      }
    } catch (error) {
      // Handle errors
      console.error("An error occurred:", error);
    }
  };

  const handleNewUserSubmit = async (e) => {
    e.preventDefault();

    // Close the modal
    closeModal();

    // Set isNewUserModalOpen to false to close the modal
    setNewUserPanelmsg1(false);
    await saveProbableStudentDetails(
      firstName,
      lastName,
      NewUserMail,
      mobileNumber
    );
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setCurrentPage("Home");
  };

  const handleFPClose = () => {
    setisFPModalOpen(false);
    setCurrentPage("Home");
  };

  const handleFPEmailChange = (e) => {
    const value = e.target.value;
    setFPemail(value);

    if (validateEmail(value)) {
      setError("");
      setEmailFPSubmit(true);
    } else {
      setError("Please enter a valid email address.");
      setEmailFPSubmit(false);
    }
  };

  const generateRandomCode = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let result = "";
    for (let i = 0; i < 4; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const handleresentOTP = async (e) => {
    e.preventDefault();
    setOTPsendSuccess(false);
    setOTPCheck(true);

    const studentUniqueId = localStorage.getItem("student_unique_id");

    if (studentUniqueId) {
      try {
        const randomcode = generateRandomCode();
        setextraletters(randomcode);

        const responsesendotp = await fetch(
          `${API_BASE_URL}/send_mailOTP/${studentUniqueId}?email=${encodeURIComponent(
            FPemail
          )}&code=${randomcode}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const dataotp = await responsesendotp.json();

        if (dataotp.status === "success") {
          setOTPsendSuccess(true);
        } else {
          setOTPsendSuccess(false);
        }
      } catch (error) {
        console.log("An error occurred");
      }
    }
  };

  const handleFPSubmit = async (e) => {
    e.preventDefault();
    if (validateEmail(FPemail)) {
      setError("");
      // Handle form submission, e.g., send a request to the server
      const studentUniqueId = localStorage.getItem("student_unique_id");

      if (studentUniqueId) {
        try {
          const response = await fetch(
            `${API_BASE_URL}/verify_mail/${studentUniqueId}?email=${FPemail}`
          );
          const data = await response.json();
          // console.log(data)

          if (data.status === "success") {
            setwrongmail("");
            setEmailVerified(true);

            const randomcode = generateRandomCode();
            setextraletters(randomcode);

            const responsesendotp = await fetch(
              `${API_BASE_URL}/send_mailOTP/${studentUniqueId}?email=${encodeURIComponent(
                FPemail
              )}&code=${randomcode}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            const dataotp = await responsesendotp.json();

            if (dataotp.status === "success") {
              setOTPsendSuccess(true);
            } else {
              setOTPsendSuccess(false);
            }
          } else {
            setwrongmail(" Wrong MAIL ID ");
            setEmailVerified(false);
          }
        } catch (error) {
          console.log("An error occurred");
        }
      } else {
        console.log("Student not found");
      }
      // console.log('Email submitted:', FPemail);
    } else {
      setError("Please enter a valid email address.");
    }
  };

  const validateEmail = (email) => {
    // Simple email validation regex
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const verify_otp = async () => {
    if (EnteredOTP === "") {
      setOTPCheck(false);
    }

    const studentUniqueId = localStorage.getItem("student_unique_id");

    if (studentUniqueId) {
      try {
        const response = await fetch(
          `${API_BASE_URL}/verify_otp/${studentUniqueId}?email=${encodeURIComponent(
            FPemail
          )}&otp=${EnteredOTP}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const dataotp = await response.json();

        if (dataotp.status === "success") {
          console.log("OTP verification success:");
          setverifiedOTP(true);
          setOTPCheck(true);
        } else {
          setverifiedOTP(false);
          setOTPCheck(false);

          console.error("OTP verification failed:", dataotp.status);
        }
      } catch (error) {
        console.error("Error verifying OTP:", error);
        // Handle network or unexpected errors
      }
    }
  };

  const handleOTPChange = (e) => {
    const value = e.target.value;
    setEnteredOTP(value);
  };

  // eslint-disable-next-line
  const validatePasswords = () => {
    if (newPassword.length < 6) {
      setError("Password must be at least 6 characters long.");
      return false;
    }
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return false;
    }
    setError("");
    return true;
  };

  const handleSubmitNewPassword = async (e) => {
    console.log("clicked");
    e.preventDefault();

    if (newPassword.length < 6) {
      alert("Password must be at least 6 characters long.");
      return false;
    }

    if (newPassword !== confirmPassword) {
      alert("Passwords do not match.");
      return false;
    }

    const studentUniqueId = localStorage.getItem("student_unique_id");

    if (studentUniqueId) {
      try {
        const url = `${API_BASE_URL}/change-password`;
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: FPemail,
            password: newPassword,
            confirm_password: confirmPassword,
            student_unique_id: studentUniqueId,
          }),
        });

        const data = await response.json();
        console.log(data);

        if (response.ok) {
          alert(
            "Password changed successfully. Kindly Relogin Again with new credentials"
          );
          setmodal1open(true);
          setmodal2open(false);
          setCurrentPage("Home");
        } else {
          alert(`Failed to change password: contact support ${data.detail}`);
        }
      } catch (error) {
        console.error("Error occurred:", error);
        alert("An error occurred while changing the password.");
      }
    }
  };

  return (
    <div>
      {/* Blur background */}
      {isModalOpen && <div className="modal-background"></div>}
      {isNewUserModalOpen && <div className="modal-background"></div>}

      {modal1open && (
        <Modal
          open={isModalOpen}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="modal-outer"
        >
          <div className="modal">
            <div className="modal-content">
              {/* Student login heading */}

              <span className="cl-btn">
                <button className="cl-button" onClick={handleClose}>
                  Close{" "}
                </button>
              </span>
              <h2>Student Login</h2>

              {/* Mobile number input */}
              <label htmlFor="mobileNumber">Enter Mobile Number:</label>
              <input
                type="text"
                id="mobileNumber"
                name="mobileNumber"
                value={mobileNumber}
                onChange={handleMobileChange}
                placeholder="Enter 10-digit mobile number"
              />
              <br />

              {/* Password input */}
              {mobileNumber.length === 10 && ExistingUser && (
                <div>
                  <label htmlFor="password">Enter Password:</label>
                  <div className="password-flex">
                    <div className="password-input">
                      <input
                        // type={showPassword ? "text" : "password"}
                        type={type}
                        id="password"
                        name="password"
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder="Enter password (min. 6 characters)"
                      />
                    </div>
                    <div className="showPassword">
                      <span
                        className="flex justify-around items-center show-icon"
                        onClick={handleToggle}
                      >
                        <Icon
                          className="absolute mr-10 show-icon"
                          icon={icon}
                          size={25}
                        />
                      </span>
                    </div>
                  </div>

                  <div className="incorrect-password">{incorrect_password}</div>
                </div>
              )}

              {/* Submit button */}
              {Submit1 && (
                <button
                  type="submit"
                  onClick={handleSubmit}
                  // disabled={!isPasswordValid}
                >
                  Submit
                </button>
              )}

              {Submit2 && (
                <button
                  type="submit"
                  onClick={handleSubmit2}
                  // disabled={!isPasswordValid}
                >
                  Submit
                </button>
              )}

              {Submit2 && (
                <div className="forgot-password">
                  <button className="forgot-password-link" onClick={openModal}>
                    Forgot Password?
                  </button>
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}

      {modal2open && (
        <Modal
          open={isFPModalOpen}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="modal-outer"
        >
          <div className="modal">
            <div className="modal-content">
              {/* Student login heading */}

              <span className="cl-btn">
                <button className="cl-button" onClick={handleFPClose}>
                  Close
                </button>
              </span>

              {!EmailVerified && (
                <>
                  <h2>Verify Email ID</h2>
                  <input
                    type="email"
                    id="FPemail"
                    name="FPemail"
                    value={FPemail}
                    onChange={handleFPEmailChange}
                    placeholder="Enter your registered email address for EMAIL Verification"
                  />
                  <br />
                  {error && (
                    <div className="error-mail">
                      <p className="error-mail" style={{ color: "red" }}>
                        {error}
                      </p>
                    </div>
                  )}
                  <div className="wrongmail">{wrongmail}</div>
                  <button type="submit" onClick={handleFPSubmit}>
                    Check Mail
                  </button>
                </>
              )}

              {EmailVerified && OTPsendSuccess && !verifiedOTP && (
                <>
                  <h5>
                    Please enter the 4-digit OTP sent to your registered email
                    address:
                    <br />
                    <br />
                    <strong style={{ color: "red" }}>{FPemail}</strong>
                  </h5>
                  <div style={{ marginTop: "20px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "5%",
                      }}
                    >
                      <div
                        style={{
                          margin: "5px",
                          border: "1px solid black",
                          padding: "0.5%",
                          borderRadius: "5px",
                          marginLeft: "10%",
                          paddingLeft: "8%",
                          paddingRight: "8%",
                        }}
                      >
                        {extraletters}
                      </div>
                      <div>-</div>
                      <input
                        type="text"
                        id="otp"
                        name="otp"
                        maxLength="4"
                        placeholder="Enter OTP"
                        style={{
                          width: "20%",
                          textAlign: "center",
                          fontSize: "80%",
                          padding: "5px",
                          margin: "5px",
                          border: "1px solid black",
                          borderRadius: "5px",
                          // marginRight: '20%'
                        }}
                        onChange={handleOTPChange}
                      />
                    </div>
                    <br />
                    <br />

                    <div>
                      {OTPCheck ? (
                        ""
                      ) : (
                        <div className="invalid-otp">Invalid OTP</div>
                      )}
                    </div>

                    <div className="otp-btn">
                      <button
                        className="otp-verify"
                        type="submit"
                        onClick={verify_otp}
                      >
                        Verify OTP
                      </button>

                      <button
                        className="otp-resend"
                        type="submit"
                        onClick={handleresentOTP}
                      >
                        Resend OTP
                      </button>
                    </div>
                  </div>
                </>
              )}

              {EmailVerified && !OTPsendSuccess && !verifiedOTP && (
                <>
                  <h5>
                    We are sending a 4-digit OTP to your registered email
                    address:
                    <br />
                    <br />
                    <strong style={{ color: "red" }}>{FPemail}</strong>. Please
                    be patient.
                  </h5>
                </>
              )}

              {EmailVerified && OTPsendSuccess && verifiedOTP && (
                <>
                  <h5>
                    OTP Verification done, let's reset the password:
                    <br />
                    <br />
                  </h5>
                  <form onSubmit={handleSubmitNewPassword}>
                    <div>
                      <label>
                        New Password:
                        <input
                          type="password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </label>
                    </div>
                    <div>
                      <label>
                        Confirm New Password:
                        <input
                          type="password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </label>
                    </div>
                    {error && <div style={{ color: "red" }}>{error}</div>}
                    <div>
                      <button type="submit">Submit New Password</button>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </Modal>
      )}

      {modal3open && (
        <Modal
          open={isNewUserModalOpen}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="modal-outer"
        >
          <div className="modal">
            <div className="modal-content">
              {NewUserPanelmsg1 && (
                <>
                  {/* Student login heading */}
                  <h2>New Student Login</h2>
                  <h3>
                    Kindly Fill below Info <br /> <br />
                    Our Team will Contact you Soon for detailed Analysis <br />{" "}
                    <br /> Also you Can Connect Us on +91 8208937591
                  </h3>

                  <div style={{ display: "flex", gap: "10%" }}>
                    <div>
                      <label htmlFor="firstName">First Name:</label>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={firstName}
                        onChange={handleFirstNameChange}
                        placeholder="Enter your first name"
                      />
                    </div>

                    <div>
                      <label htmlFor="lastName">Last Name:</label>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={lastName}
                        onChange={handleLastNameChange}
                        placeholder="Enter your last name"
                      />
                    </div>
                  </div>

                  {/* Mobile number input */}
                  <label htmlFor="mobileNumber">Enter Mobile Number:</label>
                  <input
                    type="text"
                    id="mobileNumber"
                    name="mobileNumber"
                    value={mobileNumber}
                    onChange={handleMobileChange}
                    placeholder="Enter 10-digit mobile number"
                  />
                  <br />

                  {/* Email input */}
                  {mobileNumber.length === 10 && (
                    <div>
                      <label htmlFor="email">Enter Email:</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={NewUserMail}
                        onChange={handleEmailChange}
                        placeholder="Enter your email address"
                      />
                    </div>
                  )}

                  {mobileNumber.length === 10 &&
                    firstName.length >= 2 &&
                    lastName.length >= 2 &&
                    NewUserMail.length > 2 && (
                      <button type="submit" onClick={handleNewUserSubmit}>
                        Submit
                      </button>
                    )}
                </>
              )}

              {NewUserPanelmsg2 && (
                <div>
                  Thanks you Buddy our team will connect you soon ...!!!
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}

      {/* {DesktopDevice && (
        <Modal
          open={DesktopDevice}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="modal-outer"
        >
          "Open on deskop"
        </Modal>
      )} */}

      {DesktopDevice && (
        <Modal
          open={DesktopDevice}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className="modal-outer"
        >
          <div className="modal">
            <div className="modal-content">
              {/* Student login heading */}
              <span className="cl-btn">
                <button className="cl-button" onClick={handleClose}>
                  Close{" "}
                </button>
              </span>
              <br/>
              Dear Enrolled Student, 
              <br/><br/>We have detected that you are accessing our
              learning platform from a device other than a laptop or desktop.<br/><br/>
              Please note that, for the best learning experience and to ensure
              you can engage with our content effectively, we only support
              access via laptops or desktops. <br/><br/>This policy is in place to
              encourage focused learning and enable real-time practice, which is
              essential for your success in our program.<br/><br/> Kindly switch to a
              laptop or desktop to continue using our platform. We appreciate
              your understanding and cooperation in adhering to this guideline.
            </div>
          </div>
        </Modal>
      )}
      {OpenStudentDashboard && <StudentDashBoad />}
    </div>
  );
};

export default StudentLogin;
