// Home.js
import React, { useState } from "react";
import "./Home.css";
import TakeAdmission from "./TakeAdmission";
import note from "./rem1.JPG";
import note2 from "./rem2.JPG";

import html from "./html.png";
import React1 from "./React1.webp";
import python1 from "./python1.webp";
import linux1 from "./linux1.png";
import javascript from "./javascript.webp";
import css from "./css.png";
import reactnative from "./reactnative.svg";
import expressjs from "./expressjs.png";
import fastapi from "./fastapi.png";
import flask from "./flask.png";
import MongoDB from "./mogodb.png";
import sql from "./sql.png";
import docker from "./docker.png";
import kubernetes from "./kubernetes.png";
import jenkins from "./jenkins.jpg";
import git from "./git.png";
import github from "./github.png";
import jira from "./jira.png";
import AI from "./AI.png";
import machinelearning from "./machinelearning.jpg";
import deeplearning from "./deeplearning.jpg";
import supervised from "./supervised.png";
import unsupervised from "./unsupervised.jpeg";
import neuralnetwork from "./neuralnetwork.png";

const Home = () => {
  const [showAdmissionForm, setShowAdmissionForm] = useState(false);

  const handleAdmissionButtonClick = () => {
    setShowAdmissionForm(true);
  };

  const handleCloseAdmissionForm = () => {
    setShowAdmissionForm(false);
  };

  const projects = [
    {
      title: "✨ Sneh Vivah: Where Tradition Meets Technology",
      oneLiner: "AI-powered matchmaking with the essence of Kundali Milan.",
      description:
        "Sneh Vivah is a groundbreaking platform that combines the wisdom of traditional astrology with modern AI technology to create meaningful and compatible matches. By blending the art of Kundali Milan with advanced algorithms, Sneh Vivah ensures that every match is not only astrologically aligned but also emotionally and intellectually compatible. Your journey to a harmonious and fulfilling partnership begins here.",
    },
    {
      title: "🌱 Sneh Agri Fresh: Empowering Farmers, Enriching Lives",
      oneLiner:
        "A platform connecting farmers and middle-class consumers for direct, economical vegetable trade.",
      description:
        "Sneh Agri Fresh bridges the gap between farmers and consumers by creating a seamless platform for the direct purchase and sale of fresh vegetables. By eliminating intermediaries, we ensure fair prices for farmers and affordable produce for consumers. Our mission is to empower the agricultural community while providing middle-class families with access to fresh, high-quality produce. Together, we’re building a sustainable and economical food ecosystem.",
    },
    {
      title: "⚖️ World Law: Justice Powered by AI",
      oneLiner:
        "An AI-driven system ensuring fair, unbiased, and efficient justice delivery.",
      description:
        "World Law revolutionizes the judicial process by introducing an AI-powered bot judge capable of analyzing cases presented by lawyers, leveraging a comprehensive understanding of legal frameworks. Our system aims to ensure fairness and consistency in judgments, eliminate subjective biases, and significantly reduce the burden on the judiciary. By delivering faster and more economical resolutions, World Law envisions a world where justice is accessible, impartial, and efficient for everyone.",
    },
    {
      title: "⚡ GreenGrid: AI-Powered Energy Optimization",
      oneLiner:
        "Revolutionizing energy consumption through AI and renewable sources.",
      description:
        "GreenGrid leverages AI to optimize energy usage for households, businesses, and cities. It predicts energy demand patterns, integrates renewable sources like solar and wind, and suggests ways to reduce carbon footprints. With a focus on sustainability, GreenGrid promotes energy efficiency while minimizing costs and environmental impact.",
    },
    {
      title: " 🏛️ CivicAI: Smart Governance and Public Feedback System",
      oneLiner: "Bridging the gap between citizens and governments using AI.",
      description:
        "CivicAI is a platform where citizens can report issues, suggest improvements, and provide feedback directly to local governments. The AI analyzes and prioritizes submissions based on urgency and impact, ensuring faster resolutions. With CivicAI, governments can track public sentiment, improve services, and foster better civic engagement.",
    },
    {
      title: "🤝 FairTradeAI: Blockchain and AI for Ethical Commerce",
      oneLiner: "Ensuring transparency and fairness in global trade.",
      description:
        "FairTradeAI uses blockchain and AI to verify the ethical sourcing of products. It tracks supply chains, detects fraud, and ensures fair wages for workers. By promoting transparency, FairTradeAI empowers consumers to make informed choices and supports ethical business practices.",
    },
    {
      title: "🏠 RentWise: AI for Smart Property Management",
      oneLiner: "Simplifying rental management with AI-powered solutions.",
      description:
        "RentWise helps landlords and tenants manage rental agreements, payments, and maintenance requests effortlessly. AI predicts tenant behavior, identifies market trends, and automates property listings, making rental management more efficient and hassle-free.",
    },

    {
      title: "📍 ChargeGrid: AI-Powered EV Charging Network",
      oneLiner:
        "Optimizing EV charging station placement and utilization with AI.",
      description:
        " ChargeGrid uses AI to analyze traffic patterns, population density, and electricity demand to determine the best locations for EV charging stations. It also optimizes charging schedules based on grid load, ensuring efficient energy use while reducing waiting times for EV owners.",
    },

    {
      title: "🍽️ DineEase: Smart Restaurant Ordering System",
      oneLiner:
        "Streamlining restaurant dining with table-side ordering and real-time updates.",
      description:
        " DineEase revolutionizes the dining experience by eliminating the need for constant waitstaff interaction. Customers can place orders directly from their table using a sleek interface, which also provides real-time updates on order status, from preparation to delivery.This system reduces wait times, enhances transparency, and ensures a smoother dining experience. Restaurants benefit from increased efficiency, reduced staff workload, and higher customer satisfaction. With DineEase, dining becomes effortless, efficient, and enjoyable.",
    },
  ];

  return (
    <div>
      {/* code for company name and admission button  */}
      <div className="homecontent">
        <div className="tagline">
          "Secure a Position as a <br />
          <span className="highlight">
            Full-Stack, End-to-End Artificial Intelligence Software Developer
          </span>
          <br />
          with a{" "}
          <span className="highlight1">Salary of One Lakh per Month</span>, Step
          by Step"
        </div>

        <div className="button-container">
          {!showAdmissionForm && (
            <button
              className="admission-button"
              onClick={handleAdmissionButtonClick}
            >
              Take Admission
            </button>
          )}
        </div>
      </div>

      {showAdmissionForm && (
        <TakeAdmission onClose={handleCloseAdmissionForm} />
      )}

      {/* code for video content */}
      <div className="videonote">
  <div className="impnote">
    <div className="note-container">
      <picture>
        <source srcSet={note2} media="(max-width: 750px)" />
        <source srcSet={note} media="(min-width: 751px)" />
        <img src="/src/rem1.JPG" alt="Important Note" className="note" />
      </picture>
    </div>
  </div>

  <div className="videocontent">
    <iframe
      src="https://www.youtube.com/embed/3xvnE1-gf7E"
      title="Video Content"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  </div>
</div>


      <div className="heading-container">
        <h2 className="heading-title">Why Choose Us?</h2>
        <div className="heading-underline"></div>
      </div>

      <div className="card-out">
        <div className="card-wrapper">
          <div className="info-card">
            <div className="card-title">
              <h3>Software Company Enrich In Education</h3>
            </div>
            <div className="description-section">
              <p>
                {" "}
                We are not just a teaching institute; we are a distinct entity
                engaged in both service-based and product-based industries.
              </p>
            </div>
            <div className="action-section">
              <button className="cta-button">समर्पण</button>
            </div>
          </div>
        </div>
        <div className="card-wrapper">
          <div className="info-card">
            <div className="card-title">
              <h3>Pathway to Global IT Careers</h3>
            </div>
            <div className="description-section">
              <p>
                {" "}
                Our distinguished and experienced board of directors, along with
                global pioneers in skill enhancement, ensure the successful
                placement of students in the IT industry worldwide.
              </p>
            </div>
            <div className="action-section">
              <button className="cta-button">संघर्ष</button>
            </div>
          </div>
        </div>

        <div className="card-wrapper">
          <div className="info-card">
            <div className="card-title">
              <h3>Driving Excellence in IT and Beyond</h3>
            </div>
            <div className="description-section">
              <p>
                {" "}
                Our mentor, who is the CEO of his own company, is currently
                employed while also leading two successful enterprises:
                Sneh-Achievers and another service and product-based company in
                the IT industry.
              </p>
            </div>
            <div className="action-section">
              <button className="cta-button">सिद्धि</button>
            </div>
          </div>
        </div>
      </div>

      <div className="card-out">
        <div className="card-wrapper">
          <div className="info-card">
            <div className="card-title">
              <h3>Guiding Students to Flourishing Careers </h3>
            </div>
            <div className="description-section">
              <p>
                As both an employee and employer, our CEO possesses a unique
                perspective and vision, ensuring students are placed in healthy
                and thriving environments.
              </p>
            </div>
            <div className="action-section">
              <button className="cta-button">निष्ठा</button>
            </div>
          </div>
        </div>
        <div className="card-wrapper">
          <div className="info-card">
            <div className="card-title">
              <h3>Real-Time Learning and Industry Engagement</h3>
            </div>
            <div className="description-section">
              <p>
                {" "}
                It's not just about teaching and learning; it's about engaging
                in real-time, globally standardized live product development
                within the industry.
              </p>
            </div>
            <div className="action-section">
              <button className="cta-button">सयंम </button>
            </div>
          </div>
        </div>

        <div className="card-wrapper">
          <div className="info-card">
            <div className="card-title">
              <h3>Equipping Students for Competitive Edge</h3>
            </div>
            <div className="description-section">
              <p>
                {" "}
                Not only does our program provide students with essential
                skillsets, but it also offers valuable industry experience,
                which is a key concern for interviewers.
              </p>
            </div>
            <div className="action-section">
              <button className="cta-button"> प्रतिबद्धता</button>
            </div>
          </div>
        </div>
      </div>

      <div className="card-out">
        <div className="card-wrapper">
          <div className="info-card">
            <div className="card-title">
              <h3>Partnering for Real-World Experience</h3>
            </div>
            <div className="description-section">
              <p>
                {" "}
                As we develop Proof of Concepts (POCs) for various products in
                collaboration with partner companies, our students gain a
                competitive edge.
              </p>
            </div>
            <div className="action-section">
              <button className="cta-button">कर्मयोग</button>
            </div>
          </div>
        </div>
        <div className="card-wrapper">
          <div className="info-card">
            <div className="card-title">
              <h3>Commitment to Your Success</h3>
            </div>
            <div className="description-section">
              <p>
                {" "}
                We do not make false promises, but we guarantee that you will
                achieve your placement goals, and we will support you until that
                time.
              </p>
            </div>
            <div className="action-section">
              <button className="cta-button"> सफलता</button>
            </div>
          </div>
        </div>

        <div className="card-wrapper">
          <div className="info-card">
            <div className="card-title">
              <h3>Empowered by Inspiring Backgrounds</h3>
            </div>
            <div className="description-section">
              <p>
                {" "}
                Our CEO and teachers have inspiring backgrounds, exemplifying
                how dedication and hard work can lead to extraordinary
                achievements.
              </p>
            </div>
            <div className="action-section">
              <button className="cta-button">विजय</button>
            </div>
          </div>
        </div>
      </div>

      <div className="technologies">
        <div className="heading-container">
          <h2 className="heading-title">Technologies We Offer</h2>
          <div className="heading-underline"></div>
        </div>

        <div className="technology">
          <div className="tech1">
            <div className="tech-container">
              <div className="tech-logo">
                <img src={React1} alt="React Logo" />
              </div>
              <div className="tech-name">
                <h3>React JS</h3>
              </div>
            </div>

            <div className="tech-container">
              <div className="tech-logo">
                <img src={reactnative} alt="reactnative Logo" />
              </div>
              <div className="tech-name">
                <h3>ReactNative</h3>
              </div>
            </div>
          </div>

          <div className="tech2">
            <div className="tech-container">
              <div className="tech-logo">
                <img src={javascript} alt="javascript Logo" />
              </div>
              <div className="tech-name">
                <h3>Javascript</h3>
              </div>
            </div>

            <div className="tech-container">
              <div className="tech-logo">
                <img src={css} alt="css Logo" />
              </div>
              <div className="tech-name">
                <h3>CSS</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="technology">
          <div className="tech1">
            <div className="tech-container">
              <div className="tech-logo">
                <img src={html} alt="html Logo" />
              </div>
              <div className="tech-name">
                <h3>HTML</h3>
              </div>
            </div>

            <div className="tech-container">
              <div className="tech-logo">
                <img src={python1} alt="Python1 Logo" />
              </div>
              <div className="tech-name">
                <h3>Python</h3>
              </div>
            </div>
          </div>

          <div className="tech2">
            <div className="tech-container">
              <div className="tech-logo">
                <img src={expressjs} alt="expressjs Logo" />
              </div>
              <div className="tech-name">
                <h3>ExpressJS</h3>
              </div>
            </div>

            <div className="tech-container">
              <div className="tech-logo">
                <img src={fastapi} alt="fastapi Logo" />
              </div>
              <div className="tech-name">
                <h3>FastAPI</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="technology">
          <div className="tech1">
            <div className="tech-container">
              <div className="tech-logo">
                <img src={flask} alt="flask Logo" />
              </div>
              <div className="tech-name">
                <h3>Flask</h3>
              </div>
            </div>

            <div className="tech-container">
              <div className="tech-logo">
                <img src={MongoDB} alt="MongoDB Logo" />
              </div>
              <div className="tech-name">
                <h3>MongoDB</h3>
              </div>
            </div>
          </div>

          <div className="tech2">
            <div className="tech-container">
              <div className="tech-logo">
                <img src={sql} alt="sql Logo" />
              </div>
              <div className="tech-name">
                <h3>SQL</h3>
              </div>
            </div>

            <div className="tech-container">
              <div className="tech-logo">
                <img src={docker} alt="docker Logo" />
              </div>
              <div className="tech-name">
                <h3>Docker</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="technology">
          <div className="tech1">
            <div className="tech-container">
              <div className="tech-logo">
                <img src={kubernetes} alt="kubernetes Logo" />
              </div>
              <div className="tech-name">
                <h3>Kubernetes</h3>
              </div>
            </div>

            <div className="tech-container">
              <div className="tech-logo">
                <img src={jenkins} alt="jenkins Logo" />
              </div>
              <div className="tech-name">
                <h3>Jenkins</h3>
              </div>
            </div>
          </div>

          <div className="tech2">
            <div className="tech-container">
              <div className="tech-logo">
                <img src={git} alt="git Logo" />
              </div>
              <div className="tech-name">
                <h3>Git</h3>
              </div>
            </div>

            <div className="tech-container">
              <div className="tech-logo">
                <img src={github} alt="github Logo" />
              </div>
              <div className="tech-name">
                <h3>github</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="technology">
          <div className="tech1">
            <div className="tech-container">
              <div className="tech-logo">
                <img src={jira} alt="jira Logo" />
              </div>
              <div className="tech-name">
                <h3>Jira</h3>
              </div>
            </div>

            <div className="tech-container">
              <div className="tech-logo">
                <img src={linux1} alt="linux1 Logo" />
              </div>
              <div className="tech-name">
                <h3>Linux</h3>
              </div>
            </div>
          </div>

          <div className="tech2">
            <div className="tech-container">
              <div className="tech-logo">
                <img src={AI} alt="AI Logo" />
              </div>
              <div className="tech-name">
                <h3>Artificial Intelligence</h3>
              </div>
            </div>

            <div className="tech-container">
              <div className="tech-logo">
                <img src={machinelearning} alt="machinelearning Logo" />
              </div>
              <div className="tech-name">
                <h3>Machine Learning</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="technology">
          <div className="tech1">
            <div className="tech-container">
              <div className="tech-logo">
                <img src={deeplearning} alt="deeplearning Logo" />
              </div>
              <div className="tech-name">
                <h3>Deep Learning</h3>
              </div>
            </div>

            <div className="tech-container">
              <div className="tech-logo">
                <img src={supervised} alt="supervised Logo" />
              </div>
              <div className="tech-name">
                <h3>Supervised Learning</h3>
              </div>
            </div>
          </div>

          <div className="tech2">
            <div className="tech-container">
              <div className="tech-logo">
                <img src={unsupervised} alt="unsupervised Logo" />
              </div>
              <div className="tech-name">
                <h3>Unsupervised Intelligence</h3>
              </div>
            </div>

            <div className="tech-container">
              <div className="tech-logo">
                <img src={neuralnetwork} alt="neuralnetwork Logo" />
              </div>
              <div className="tech-name">
                <h3>Neural Network</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="technologies">
        <div className="heading-container">
          <h2 className="heading-title">Our Projects</h2>
          <div className="heading-underline"></div>
        </div>
      </div>

      <div className="projects-container">
        {projects.map((project, index) => (
          <div className="project-card" key={index}>
            <h5 className="project-title">{project.title}</h5>
            <p className="project-one-liner">{project.oneLiner}</p>
            <p className="project-description">{project.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
